const countries = [
  { value: 0, label: "Afghanistan" },
  { value: 1, label: "Åland Islands" },
  { value: 2, label: "Albania" },
  { value: 3, label: "Algeria" },
  { value: 4, label: "American Samoa" },
  { value: 5, label: "Andorra" },
  { value: 6, label: "Angola" },
  { value: 7, label: "Anguilla" },
  { value: 8, label: "Antarctica" },
  { value: 9, label: "Antigua and Barbuda" },
  { value: 10, label: "Argentina" },
  { value: 11, label: "Armenia" },
  { value: 12, label: "Aruba" },
  { value: 13, label: "Australia" },
  { value: 14, label: "Austria" },
  { value: 15, label: "Azerbaijan" },
  { value: 16, label: "Bahamas " },
  { value: 17, label: "Bahrain" },
  { value: 18, label: "Bangladesh" },
  { value: 19, label: "Barbados" },
  { value: 20, label: "Belarus" },
  { value: 21, label: "Belgium" },
  { value: 22, label: "Belize" },
  { value: 23, label: "Benin" },
  { value: 24, label: "Bermuda" },
  { value: 25, label: "Bhutan" },
  { value: 26, label: "Bolivia (Plurinational State of)" },
  { value: 27, label: "Bonaire, Sint Eustatius and Saba" },
  { value: 28, label: "Bosnia and Herzegovina" },
  { value: 29, label: "Botswana" },
  { value: 30, label: "Bouvet Island" },
  { value: 31, label: "Brazil" },
  { value: 32, label: "British Indian Ocean Territory " },
  { value: 33, label: "Brunei Darussalam" },
  { value: 34, label: "Bulgaria" },
  { value: 35, label: "Burkina Faso" },
  { value: 36, label: "Burundi" },
  { value: 37, label: "Cabo Verde" },
  { value: 38, label: "Cambodia" },
  { value: 39, label: "Cameroon" },
  { value: 40, label: "Canada" },
  { value: 41, label: "Cayman Islands " },
  { value: 42, label: "Central African Republic " },
  { value: 43, label: "Chad" },
  { value: 44, label: "Chile" },
  { value: 45, label: "China" },
  { value: 46, label: "Christmas Island" },
  { value: 47, label: "Cocos (Keeling) Islands " },
  { value: 48, label: "Colombia" },
  { value: 49, label: "Comoros " },
  { value: 50, label: "Congo (the Democratic Republic of the)" },
  { value: 51, label: "Congo " },
  { value: 52, label: "Cook Islands " },
  { value: 53, label: "Costa Rica" },
  { value: 54, label: "Croatia" },
  { value: 55, label: "Cuba" },
  { value: 56, label: "Curaçao" },
  { value: 57, label: "Cyprus" },
  { value: 58, label: "Czechia" },
  { value: 59, label: "Côte d'Ivoire" },
  { value: 60, label: "Denmark" },
  { value: 61, label: "Djibouti" },
  { value: 62, label: "Dominica" },
  { value: 63, label: "Dominican Republic " },
  { value: 64, label: "Ecuador" },
  { value: 65, label: "Egypt" },
  { value: 66, label: "El Salvador" },
  { value: 67, label: "Equatorial Guinea" },
  { value: 68, label: "Eritrea" },
  { value: 69, label: "Estonia" },
  { value: 70, label: "Eswatini" },
  { value: 71, label: "Ethiopia" },
  { value: 72, label: "Falkland Islands  [Malvinas]" },
  { value: 73, label: "Faroe Islands " },
  { value: 74, label: "Fiji" },
  { value: 75, label: "Finland" },
  { value: 76, label: "France" },
  { value: 77, label: "French Guiana" },
  { value: 78, label: "French Polynesia" },
  { value: 79, label: "French Southern Territories " },
  { value: 80, label: "Gabon" },
  { value: 81, label: "Gambia " },
  { value: 82, label: "Georgia" },
  { value: 83, label: "Germany" },
  { value: 84, label: "Ghana" },
  { value: 85, label: "Gibraltar" },
  { value: 86, label: "Greece" },
  { value: 87, label: "Greenland" },
  { value: 88, label: "Grenada" },
  { value: 89, label: "Guadeloupe" },
  { value: 90, label: "Guam" },
  { value: 91, label: "Guatemala" },
  { value: 92, label: "Guernsey" },
  { value: 93, label: "Guinea" },
  { value: 94, label: "Guinea-Bissau" },
  { value: 95, label: "Guyana" },
  { value: 96, label: "Haiti" },
  { value: 97, label: "Heard Island and McDonald Islands" },
  { value: 98, label: "Holy See " },
  { value: 99, label: "Honduras" },
  { value: 100, label: "Hong Kong" },
  { value: 101, label: "Hungary" },
  { value: 102, label: "Iceland" },
  { value: 103, label: "India" },
  { value: 104, label: "Indonesia" },
  { value: 105, label: "Iran (Islamic Republic of)" },
  { value: 106, label: "Iraq" },
  { value: 107, label: "Ireland" },
  { value: 108, label: "Isle of Man" },
  { value: 109, label: "Israel" },
  { value: 110, label: "Italy" },
  { value: 111, label: "Jamaica" },
  { value: 112, label: "Japan" },
  { value: 113, label: "Jersey" },
  { value: 114, label: "Jordan" },
  { value: 115, label: "Kazakhstan" },
  { value: 116, label: "Kenya" },
  { value: 117, label: "Kiribati" },
  { value: 118, label: "Korea (the Democratic People's Republic of)" },
  { value: 119, label: "Korea (the Republic of)" },
  { value: 120, label: "Kuwait" },
  { value: 121, label: "Kyrgyzstan" },
  { value: 122, label: "Lao People's Democratic Republic " },
  { value: 123, label: "Latvia" },
  { value: 124, label: "Lebanon" },
  { value: 125, label: "Lesotho" },
  { value: 126, label: "Liberia" },
  { value: 127, label: "Libya" },
  { value: 128, label: "Liechtenstein" },
  { value: 129, label: "Lithuania" },
  { value: 130, label: "Luxembourg" },
  { value: 131, label: "Macao" },
  { value: 132, label: "Madagascar" },
  { value: 133, label: "Malawi" },
  { value: 134, label: "Malaysia" },
  { value: 135, label: "Maldives" },
  { value: 136, label: "Mali" },
  { value: 137, label: "Malta" },
  { value: 138, label: "Marshall Islands " },
  { value: 139, label: "Martinique" },
  { value: 140, label: "Mauritania" },
  { value: 141, label: "Mauritius" },
  { value: 142, label: "Mayotte" },
  { value: 143, label: "Mexico" },
  { value: 144, label: "Micronesia (Federated States of)" },
  { value: 145, label: "Moldova (the Republic of)" },
  { value: 146, label: "Monaco" },
  { value: 147, label: "Mongolia" },
  { value: 148, label: "Montenegro" },
  { value: 149, label: "Montserrat" },
  { value: 150, label: "Morocco" },
  { value: 151, label: "Mozambique" },
  { value: 152, label: "Myanmar" },
  { value: 153, label: "Namibia" },
  { value: 154, label: "Nauru" },
  { value: 155, label: "Nepal" },
  { value: 156, label: "Netherlands " },
  { value: 157, label: "New Caledonia" },
  { value: 158, label: "New Zealand" },
  { value: 159, label: "Nicaragua" },
  { value: 160, label: "Niger " },
  { value: 161, label: "Nigeria" },
  { value: 162, label: "Niue" },
  { value: 163, label: "Norfolk Island" },
  { value: 164, label: "Northern Mariana Islands " },
  { value: 165, label: "Norway" },
  { value: 166, label: "Oman" },
  { value: 167, label: "Pakistan" },
  { value: 168, label: "Palau" },
  { value: 169, label: "Palestine, State of" },
  { value: 170, label: "Panama" },
  { value: 171, label: "Papua New Guinea" },
  { value: 172, label: "Paraguay" },
  { value: 173, label: "Peru" },
  { value: 174, label: "Philippines " },
  { value: 175, label: "Pitcairn" },
  { value: 176, label: "Poland" },
  { value: 177, label: "Portugal" },
  { value: 178, label: "Puerto Rico" },
  { value: 179, label: "Qatar" },
  { value: 180, label: "Republic of North Macedonia" },
  { value: 181, label: "Romania" },
  { value: 182, label: "Russian Federation " },
  { value: 183, label: "Rwanda" },
  { value: 184, label: "Réunion" },
  { value: 185, label: "Saint Barthélemy" },
  { value: 186, label: "Saint Helena, Ascension and Tristan da Cunha" },
  { value: 187, label: "Saint Kitts and Nevis" },
  { value: 188, label: "Saint Lucia" },
  { value: 189, label: "Saint Martin (French part)" },
  { value: 190, label: "Saint Pierre and Miquelon" },
  { value: 191, label: "Saint Vincent and the Grenadines" },
  { value: 192, label: "Samoa" },
  { value: 193, label: "San Marino" },
  { value: 194, label: "Sao Tome and Principe" },
  { value: 195, label: "Saudi Arabia" },
  { value: 196, label: "Senegal" },
  { value: 197, label: "Serbia" },
  { value: 198, label: "Seychelles" },
  { value: 199, label: "Sierra Leone" },
  { value: 200, label: "Singapore" },
  { value: 201, label: "Sint Maarten (Dutch part)" },
  { value: 202, label: "Slovakia" },
  { value: 203, label: "Slovenia" },
  { value: 204, label: "Solomon Islands" },
  { value: 205, label: "Somalia" },
  { value: 206, label: "South Africa" },
  { value: 207, label: "South Georgia and the South Sandwich Islands" },
  { value: 208, label: "South Sudan" },
  { value: 209, label: "Spain" },
  { value: 210, label: "Sri Lanka" },
  { value: 211, label: "Sudan " },
  { value: 212, label: "Suriname" },
  { value: 213, label: "Svalbard and Jan Mayen" },
  { value: 214, label: "Sweden" },
  { value: 215, label: "Switzerland" },
  { value: 216, label: "Syrian Arab Republic" },
  { value: 217, label: "Taiwan (Province of China)" },
  { value: 218, label: "Tajikistan" },
  { value: 219, label: "Tanzania, United Republic of" },
  { value: 220, label: "Thailand" },
  { value: 221, label: "Timor-Leste" },
  { value: 222, label: "Togo" },
  { value: 223, label: "Tokelau" },
  { value: 224, label: "Tonga" },
  { value: 225, label: "Trinidad and Tobago" },
  { value: 226, label: "Tunisia" },
  { value: 227, label: "Turkey" },
  { value: 228, label: "Turkmenistan" },
  { value: 229, label: "Turks and Caicos Islands " },
  { value: 230, label: "Tuvalu" },
  { value: 231, label: "Uganda" },
  { value: 232, label: "Ukraine" },
  { value: 233, label: "United Arab Emirates " },
  {
    value: 234,
    label: "United Kingdom of Great Britain and Northern Ireland ",
  },
  { value: 235, label: "United States Minor Outlying Islands " },
  { value: 236, label: "United States of America (USA)" },
  { value: 237, label: "Uruguay" },
  { value: 238, label: "Uzbekistan" },
  { value: 239, label: "Vanuatu" },
  { value: 240, label: "Venezuela (Bolivarian Republic of)" },
  { value: 241, label: "Viet Nam" },
  { value: 242, label: "Virgin Islands (British)" },
  { value: 243, label: "Virgin Islands (U.S.)" },
  { value: 244, label: "Wallis and Futuna" },
  { value: 245, label: "Western Sahara" },
  { value: 246, label: "Yemen" },
  { value: 247, label: "Zambia" },
  { value: 248, label: "Zimbabwe" },
];
//   a.map((item) => {
//     let c = 0;
//     aar.push({
//       value: aar.length,
//       label: item,
//     });
//   });
export default countries;
