const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    alignItems: "start",
    marginBottom: "25px",
  },
};

export default styles;
