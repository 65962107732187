import React from "react";

function TermsAndConditions() {
  return (
    <main id="main" className="snipcss-Xo5od">
      <style>
        {`@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap');

        body {
          /* CSS Variables that may have been missed get put on body */
          --toastify-color-info: #3498db;
          --toastify-color-success: #07bc0c;
          --toastify-color-warning: #f1c40f;
          --toastify-color-error: #e74c3c;
        }

        @media all {
          * {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          #wrapper {
            background-color: #fff;
            position: relative;
          }

          body {
            margin: 0;
            color: #777;
            scroll-behavior: smooth;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            line-height: 1.6;
          }
        }

        body {
          font-family: "Lato", sans-serif;
          font-weight: 400;
        }

        @media all {
          .full-width {
            width: 100% !important;
            max-width: 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            display: block;
          }

          html {
            font-family: sans-serif;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background-attachment: fixed;
            -webkit-tap-highlight-color: transparent;
            overflow-x: hidden;
            background-color: #5b5b5b;
          }
        }

        html {
          background-color: #000000;
        }

        @media all {
          main {
            display: block;
          }

          #main {
            background-color: #fff;
            position: relative;
          }

          *,:before,:after {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          .row {
            display: flex;
            display: -ms-flexbox;
            display: -webkit-box;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            max-width: 1080px;
            -js-display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
          }

          .page-wrapper {
            padding-top: 30px;
            padding-bottom: 30px;
          }

          .container:after,.row:after,.clearfix:after,.clear:after {
            content: "";
            display: table;
            clear: both;
          }

          .col {
            position: relative;
            margin: 0;
            padding: 0 15px 30px;
            width: 100%;
          }
        }

        @media screen and (min-width: 850px) {
          .large-12 {
            max-width: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
          }
        }

        @media all {
          header {
            display: block;
          }

          .text-center {
            text-align: center;
          }

          .entry-content {
            padding-top: 1.5em;
            padding-bottom: 1.5em;
          }

          h1 {
            color: #555;
            width: 100%;
            margin-top: 0;
            margin-bottom: .5em;
            text-rendering: optimizeSpeed;
            font-size: 1.7em;
            line-height: 1.3;
          }
        }

        h1 {
          font-family: "Lato", sans-serif;
          font-weight: 700;
        }

        @media all {
          .is-divider {
            height: 3px;
            display: block;
            background-color: rgba(0,0,0,.1);
            margin: 1em 0 1em;
            width: 100%;
            max-width: 30px;
          }

          .text-center > div ,.text-center .is-divider {
            margin-left: auto;
            margin-right: auto;
          }

          p {
            margin-bottom: 1.3em;
            margin-top: 0;
          }

          ul {
            list-style: disc;
            margin-top: 0;
            padding: 0;
            margin-bottom: 1.3em;
          }
        }

        ul {
          box-sizing: border-box;
        }

        @media all {
          a {
            background-color: transparent;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            color: #334862;
            text-decoration: none;
          }
        }

        a {
          color: #26c39f;
        }

        @media all {
          a:active,a:hover {
            outline-width: 0;
          }

          a:hover {
            color: #000;
          }
        }

        a:hover {
          color: #111111;
        }

        @media all {
          strong {
            font-weight: bolder;
          }

          li {
            margin-bottom: .6em;
          }

          .entry-content ul li {
            margin-left: 1.3em;
          }
        }`}
      </style>

      <div class="row page-wrapper">
        <div id="content" class="large-12 col" role="main">
          <header class="entry-header text-center">
            <h1 class="entry-title">Terms and conditions of Service</h1>
            <div class="is-divider medium"></div>
          </header>
          <div class="entry-content">
            <p>
              Last updated: 25
              <sup>th</sup>
              -November-2023
            </p>
            <p>
              Please read the following Terms and Conditions meticulously before
              using DoctorAI’s mobile version and website Version available at
              &nbsp;
              <a
                rel="noreferrer noopener"
                href="http://https//doctoraicompany.com"
                target="_blank"
              >
                www.doctoraicompan
              </a>
              <a
                href="http://https//doctoraicompany.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                y
              </a>
              <a
                rel="noreferrer noopener"
                href="http://https//doctoraicompany.com"
                target="_blank"
              >
                .com.
              </a>
            </p>
            <p>
              By using our products, and services, or visiting our website, you
              are immediately bound to these terms and conditions of service.
            </p>
            <p>
              <strong>Chapter 1: Your account.</strong>
            </p>
            <p>
              <strong>For the Public:</strong>
            </p>
            <p>
              DoctorAI app is free to download through your app store, when you
              are creating an account on DoctorAI, you are recommended to
              provide and use accurate, complete, and updated information
              through your registration process.
            </p>
            <p>
              Your submission of personal information through the registration
              process is amended by our privacy policy.
            </p>
            <p>
              You are in charge of maintaining the security and privacy of your
              Device, if you suspect any breach or unauthorized access to your
              account immediately contact our customer support at
              <a href="mailto:support@doctoraicompany.com">
                support@doctoraicompany.com
              </a>
              or Contact us, for an in-depth examination of your account.
            </p>
            <p>
              You have all the rights to delete your account at any time, so all
              these terms and conditions will end as an agreement between you
              and DoctorAI.
            </p>
            <p>
              <strong>
                Chapter 2: Shared Contents &amp; Responsibilities.
              </strong>
            </p>
            <p>
              Your grant of access to DoctorAI’s products, services, and
              website; is in accordance with the following
              responsibilities/stipulations:
            </p>
            <ul>
              <li>
                You should not attempt to use third-party services for
                fraudulent activities.
              </li>
              <li>
                You must not use a third party to test DoctorAI’s platform
                vulnerabilities.
              </li>
              <li>
                You should not breach access to the platforms or intent to reach
                other’s users’ data.
              </li>
              <li>
                You should not use the platform to transmit viruses, Trojan
                horses, or worms, if during the process your device gets harmed
                by them, you will take full responsibility.
              </li>
              <li>
                You should not use DoctorAI to make advertisements, this applies
                to all users.
              </li>
            </ul>
            <p>
              Failure to follow these stipulations/responsibilities will result
              in the suspension of your account as stipulated in the
              “Termination” chapter below.
            </p>
            <p>
              <strong>Chapter 4: Age restriction</strong>
            </p>
            <p>
              DoctorAI’s website and mobile platforms are not allowed for those
              under the age of 16 (an age which may vary and dependent on the
              user’s country of residency by the definition of an “Age of
              consent”), if you are in this range and below, you are not
              permitted to register or subscribe to our services on the website
              and the apps.
            </p>
            <p>
              We will take the right to terminate any account registered under
              16 years old, we strongly advise you to use our platforms under
              the consent of your parent or legal guardian.
            </p>
            <p>
              <strong>Chapter 5: Devices, Software, and Permissions.</strong>
            </p>
            <p>
              For us to provide a seamless experience through our products,
              services, and website, you will need to use smartphones (Android
              and IOS), and PCs that we do not provide at DoctorAI.
            </p>
            <p>
              You agree with DoctorAI to access components mentioned in app installation,
              Thus DoctorAI remains strongly committed to your
              security, safety, and privacy on all your devices and through all
              our products, services, and website.
            </p>
            <p>
              <strong>Chapter 6: Privacy through encryption.</strong>
            </p>
            <p>
              DoctorAI is committed to security, safety, and privacy. Through
              our Privacy policy, we have enlightened our end-to-end encryption
              algorithm, the permissions we request, the data we collect, and
              your rights to your information.
            </p>
            <p>
              <strong>Chapter 7: Internet Services</strong>
            </p>
            <p>
              DoctorAI doesn’t provide internet services thus requires us to use
              all our platforms. Please refer to your internet service provider
              for all information regarding your carrier plans, internet fees,
              and taxes.
            </p>
            <p>
              <strong>Chapter 8: Payment.</strong>
            </p>
            <p>
              DoctorAI holds all the rights of price quotations regarding
              subscription plans to its services. You agree to pay the
              subscription fees to use a service or a product, we take the
              responsibility to display all our payment channels.
            </p>
            <p>
              Payments to access these services/products are made before you
              start using them with a time frame defined in the service/product
              description.
            </p>
            <p>
              Subscription plans and price quotations are prone to change at any
              time, thus no change will be made within your subscription term.
              Your new subscription will be bound to the new pricing terms
            </p>
            <p>
              <strong>Chapter 9: Refunds &amp; Returns</strong>
            </p>
            <p>
              As discussed above in Chapter 9: Payment; DoctorAI would like to
              enumerate conditions by which subscriptions or paid consultations
              may be terminated, conditions which may be driven by the users or
              by DoctorAI.
            </p>
            <p>
              <strong>Premium Features:</strong>
            </p>
            <ul>
              <li>
                You are allowed to only ask for a refund when you didn’t use the
                credits offered through subscription premium features offered by
                DoctorAI through its platforms for one reason or another; please
                note that you are given 24 hours post “subscription” to request
                a refund only when you didn’t use credits you are offered. If
                beyond 24 hours or credits have been used, DoctorAI won’t
                receive your refund query.
              </li>
              <li>
                You are allowed to ask for a refund when you just subscribed to
                a premium feature unintentionally and you must not have used the
                credits we offered. Please do contact us immediately before the
                next 24 hours.
              </li>
            </ul>
            <p>
              Please notify us of all these queries at
              <a href="mailto:report@doctoraicompany.com">
                support@doctoraicompany.com
              </a>
              or Contact Us in the app or on our website; DoctorAI will take the
              responsibility to examine the context of the events and will take
              action accordingly, please note that depending on your request
              DoctorAI will take up to 24 hours to provide a concrete response
              and a refund if applicable.
            </p>
            <p>
              <strong>Chapter 10: Third-party services.</strong>
            </p>
            <p>
              DoctorAI uses a series of services and resources taken from
              third-party services on its platforms as discussed below:
            </p>
            <ul>
              <li>
                <strong>Payment channels:</strong>
                DoctorAI would like to communicate to all its users that
                stipulations related to the use of any of the payment channels
                available via DoctorAI through any financial transaction are
                mandated by the financial institution itself through its privacy
                policy and terms or conditions of services. DoctorAI remains
                committed to receiving any query related to any malfunction
                encountered while using any of the payment channels.
              </li>
            </ul>

            <ul>
              <li>
                <strong>Cross-Check System:</strong>
                To ensure the highest level of safety and accuracy, our service
                implements a comprehensive cross-check system. This system
                utilizes a variety of sources, one of which is the esteemed
                National Library of Medicine (NLM), particularly drawing from
                PubMed articles. We duly recognize and acknowledge that a
                portion of our reference data is sourced from the NLM.
                Nonetheless, users should be aware that the data incorporated
                into our products, services, or applications might not always
                represent the latest or most precise information available from
                the NLM.
              </li>
            </ul>
            <p>
              <strong>Chapter 11: Copyright and Trademarks</strong>
            </p>
            <p>
              DoctorAI encourages everyone to respect their intellectual
              property rights as we do for others. If you strongly believe that
              any of our products, services, and website contents violate your
              intellectual property, please do notify us immediately at
              <a
                href="mailto:support@doctoraicompany.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                support@doctoraicompany.com
              </a>
              or Contact us in the app or website.
            </p>
            <p>
              DoctorAI, logo, service marks, graphics, and all related
              trademarks are registered under DoctorAI’s licensors. We do not
              allow anyone to reproduce, mimic, replicate our logo, trademarks,
              graphics.
            </p>
            <p>
              <strong>Chapter 12: Updates &amp; Changes</strong>
            </p>
            <p>
              DoctorAI retains all the rights to conduct the changes and updates
              regarding the configurations and specifications of all its
              platforms, some will be conducted over the air, through app store
              updates (IOS &amp; Android), and website upgrades/maintenance.
            </p>
            <p>
              You are recommended to accept all these changes and updates,
              unless these changes reduce the performance of your device, or
              decline your experience with our platforms and services. In such
              contexts, you will contact our customer support at
              <a
                rel="noreferrer noopener"
                href="mailto:support@doctorAI.com"
                target="_blank"
              >
                support@doctoraicompany.com
              </a>
              or Contact us
            </p>
            <p>
              <strong>
                Chapter 13: Termination &amp; Temporary suspension
              </strong>
              .
            </p>
            <p>
              You may terminate your agreement and delete your account at any
              time through the app or the website, a confirmation email will be
              sent to you.
            </p>
            <p>
              DoctorAI holds all rights to terminate its relationship with you,
              through the accessibility to the website and mobile platforms at
              any time in the following conditions.
            </p>
            <ul>
              <li>
                If you use our platforms for other purposes like intimidating,
                threatening, harassing other users.
              </li>
              <li>
                If you use our platform to instigate users to misconduct,
                violence, crime, child abuse, or any other illegal activity.
              </li>
              <li>
                If your account becomes inactive for an extended period after
                registration.
              </li>
              <li>
                If you use your account to impersonate/mimic any other user or
                someone else.
              </li>
              <li>
                If you use the platform to publish, circulate fake statements
                that would be considered illegal by your local law enforcement
                orders.
              </li>
              <li>
                If DoctorAI suspects you of using its platforms fraudulently or
                you are allowing any third party to use them fraudulently.
              </li>
              <li>
                If DoctorAI suspects you of using the platform for any form of
                advertisement, this applies to all users, physicians, and
                pharmacists.
              </li>
              <li>
                Attempting to access, change, or modify our platforms through
                reverse engineering or other unauthorized manners.
              </li>
              <li>
                Using the platforms to transfer viruses, worms, trojan horses
                that may harm the platform, the receiver, or other users.
              </li>
              <li>
                Replicating our platform, services, or API in a product that
                functions algorithmically like ours.
              </li>
              <li>
                If you refuse to pay any of your subscription plans in due to
                time.
              </li>
              <li>
                If you violate these terms and conditions or any other agreement
                with DoctorAI.
              </li>
            </ul>
            <p>
              After termination of your account for the above-mentioned reasons,
              you will not be refunded and you will be revoked to access all the
              doctor’s services.
            </p>
            <p>
              <strong>Chapter 14: Disclaimers.</strong>
            </p>
            <p>
              Your access to DoctorAI’s platforms is subject to the following
              disclaimers:
            </p>
            <ul>
              <li>
                You must use our services, products, or website at your own
                risk.
              </li>
              <li>
                We do not guarantee that your journey with DoctorAI will be
                error-free,24/7 operational, or without imperfections.
              </li>
              <li>
                We do not guarantee that results given through the use of our
                services are error free.
              </li>
              <li>
                We will not be responsible for professional malpractices made by
                our users through the tools offered by DoctorAI to them.
              </li>
              <li>
                We do not take control or review your actions on all our
                platforms.
              </li>
              <li>
                We are not obligated to control or review how our users navigate
                through our services.
              </li>
              <li>
                We will not be responsible if our services are interrupted by
                events far beyond our control.
              </li>
              <li>
                You are responsible for complying with local laws and
                regulations when using DoctorAI services
              </li>
              <li>
                Any user of DoctorAI services shall indemnify and hold harmless
                DoctorAI Ltd, and its directors, officers, employees, agents,
                stockholders, affiliates, subcontractors and customers from and
                against all allegations, claims, actions, suits, demands,
                damages, liabilities, obligations, losses, settlements,
                judgments, costs and expenses (including without limitation
                attorneys’ fees and costs) which arise out of, relate to or
                result from any use of our services by user.
              </li>
            </ul>
            <p>
              <strong>Chapter 15: Dispute resolution</strong>
            </p>
            <p>
              Any user of DoctorAI services shall indemnify and hold harmless
              DoctorAI Ltd, and its directors, officers, employees, agents,
              stockholders, affiliates, subcontractors and customers from and
              against all allegations, claims, actions, suits, demands, damages,
              liabilities, obligations, losses, settlements, judgments, costs
              and expenses (including without limitation attorneys’ fees and
              costs) which arise out of, relate to or result from any use of our
              services by user.
            </p>
            <p>
              <strong>Chapter 16: Contact us.</strong>
            </p>
            <p>
              For any inquiries, don’t hesitate to contact us via
              <a
                href="mailto:support@doctoraicompany.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                support@doctoraicompany.com
              </a>
              or Contact us in the app
            </p>
            <p>
              <strong>Chapter 17: Miscellaneous</strong>
            </p>
            <p>
              -Terms and conditions will be updated from time to time to reflect
              the growth of DoctorAI and the effectiveness of the services we
              provide. DoctorAI takes the responsibility to inform you of any
              changes before applying them.
            </p>
            <p>
              Effective as of 25
              <sup>th</sup>
              -November-2023
            </p>
            <p>DoctorAI</p>
            <p>
              <a href="mailto:support@doctorAI.com">
                support@doctoraicompany.com
              </a>
            </p>
            <p>
              <a
                href="https://doctoraicompany.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                www.doctoraicompany.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default TermsAndConditions;
