import React from "react";
import KCTitle from "../reusable/KCTitle";
import KCButton from "../reusable/form/KCButton";

import downloadIcon from "../../assets/icons/downloadIcon.svg";
import CarouselItem from "./CarouselItem";

function Carousel() {
  return (
    <div id="models">
      <KCTitle
        subtitle="AI MODELS"
        title="Download the document for More details ON THE AI models"
      >
        <a
          href="https://drive.usercontent.google.com/u/0/uc?id=1Gl1cQvN2eJ5vSiClS_7Qzrqo20JuxYnC&export=download"
          download="DOCTORAI Brochure.pdf"
          style={{ textDecoration: "none" }}
        >
          <KCButton icon={<img src={downloadIcon} alt="download" />}>
            Download descriptions
          </KCButton>
        </a>
      </KCTitle>
      <CarouselItem />
    </div>
  );
}

export default Carousel;
